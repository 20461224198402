import React from 'react'

// Components
import Icon from '../Icon'

// Assets
import hash from '../../images/hash.svg'

import { Card } from './styles'

interface ICard {
  className?: string;
  direction?: string;
  icon: {
    name: string;
    directory: string;
  };
  title: string;
  description: string;
}

const CardComponent: React.FC<ICard> = ({ className, direction, icon, title, description }: ICard) => {
  return (
    <Card direction={direction} className={`bg-white w-100 ${className}`}>
      {
        icon.name === 'hash' ? (
          <img width='24' height='24' src={hash} />
        ) : (
          <Icon icon={icon.name} directory={icon.directory} color='orange-medium' />
        )
      }
      <div>
        <h4 className='fs-14 lh-16 text-left text-gray-700 fw-600'>{title}</h4>
        <p className='fs-12 lh-14 text-gray-700 text-left mb-0'>{description}</p>
      </div>
    </Card>
  )
}

CardComponent.defaultProps = {
  direction: 'horizontal',
}

export default CardComponent
