import styled, { css } from 'styled-components'
import breakpoints from '../../styles/breakpoints'

interface ICard {
  direction?: string;
}

export const HorizontalCard = css`

  svg {
    width: 40px;
    height: 40px;
  }

  > div {
    margin-left: 16px;

    h4 {
      margin-bottom: 0;
    }
  }
`

export const VerticalCard = css`
  flex-direction: column;
  align-items: initial;
  
  @media (min-width: ${breakpoints.md}) {
    height: 100%;
    min-height: 240px;
  }
  @media(min-width: ${breakpoints.xl}) {
    min-height: 190px;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  > div {

    h4 {
      margin: 15px 0 10px;
    }
  }
`

export const Card = styled.div<ICard>`
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  min-height: 125px;
  align-items: center;

  @media(min-width: ${breakpoints.lg}) {
    min-height: 74px;
  }

  ${(props: ICard) => {
    switch (props.direction) {
      case 'horizontal':
        return HorizontalCard
      case 'vertical':
        return VerticalCard
    }
  }}
`
