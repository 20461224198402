import React from 'react'

// Components
import Card from '../../../components/Card'

// Styles
import { SectionWrapper, CardContainer } from './style'

const Principios = () => {
  return (
    <SectionWrapper className='py-5'>
      <div className='container'>
        <header className='text-center'>
          <h3 className='f-sora fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-48 text-white px-3 px-md-0'>
            Nossos princípios geram nossos resultados <br/>É assim que nos guiamos 
          </h3>
        </header>
        <CardContainer>
          <div className='col-12 col-md-4 py-3 px-md-2'>
            <Card
              direction='vertical'
              icon={{ name: 'hash', directory: 'v2' }}
              title='Experiência de mercado'
              description='Nossas decisões sempre possuem embasamento técnico, combinadas com a experiência de mercado do nosso time.'
              className='order-1 order-md-1'
            />
          </div>
          <div className='col-12 col-md-4 py-3 px-md-2'>
            <Card
              direction='vertical'
              icon={{ name: 'chart', directory: 'v2/products/investimentos' }}
              title='Gerenciamento de riscos'
              description='Nossa equipe de gestão de riscos atua com independência e utiliza as melhores ferramentas do mercado
                para garantir segurança e aderência aos mandatos.'
                className='order-3 order-md-2'
            />
          </div>
          <div className='col-12 col-md-4 py-3 px-md-2'>
            <Card
              direction='vertical'
              icon={{ name: 'homebroker-coin', directory: 'v2/products/investimentos' }}
              title='Consistência de resultados'
              description='Aplicamos processos colegiados e bem definidos, sempre suportados por uma equipe qualificada, com visão e cultura de longo prazo.'
              className='order-5 order-md-3'
            />
          </div>
          <div className='col-12 col-md-4 py-3 px-md-2'>
            <Card
              direction='vertical'
              icon={{ name: 'home-broker', directory: 'v2/products/investimentos' }}
              title='Monitoramento constante'
              description='Mantemos contato frequente com as companhias nas quais investimos e monitoramos todos os ativos de forma
                contínua. Com isso, reagimos com rapidez e eficácia às variações de mercado.'
              className='order-2 order-md-4'
            />
          </div>
          <div className='col-12 col-md-4 py-3 px-md-2'>
            <Card
              direction='vertical'
              icon={{ name: 'renda-variavel', directory: 'v2/products/investimentos' }}
              title='Alinhamento de interesses'
              description='Nossos sócios também investem seus recursos em nossos fundos, o que reforça nossa fidelidade à busca pelos
                melhores resultados com segurança.'
              className='order-4 order-md-5'
            />
          </div>
          <div className='col-12 col-md-4 py-3 px-md-2'>
            <Card
              direction='vertical'
              icon={{ name: 'sou-investidor', directory: 'v2/products/investimentos' }}
              title='Relacionamento com investidores'
              description='Com nossa equipe sempre à disposição, acreditamos em uma relação transparente e de grande proximidade com cada cliente e parceiro.'
              className='order-6 order-md-6'
            />
          </div>
        </CardContainer>
      </div>
    </SectionWrapper>
  )
}

export default Principios
